import { SceneComponentProps, SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import './estilos.css';
import React from 'react';

interface PageState extends SceneObjectState {
  isActive: boolean;
}

export class AlertasPage extends SceneObjectBase<PageState> {
  public static Component = AlertasPageRenderer;
}

function AlertasPageRenderer({ model }: SceneComponentProps<AlertasPage>) {
  const { isActive } = model.useState();
  //giga scuffed but it works (tenho que mudar)

  return (
    <>
      <div>
        <div>{isActive}</div>
        <div className="center">
          <div>
            <button className="button"> Criar Alerta </button>
          </div>
          <div>
            <button className="button"> Pontos de contacto </button>
          </div>
        </div>
      </div>
    </>
  );
}
