import { SceneComponentProps, SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import '../estilos.css';
import React from 'react';

interface PageState extends SceneObjectState {
  isActive: boolean;
}

export class SensorPage extends SceneObjectBase<PageState> {
  public static Component = SensorPageRenderer;
}

function SensorPageRenderer({ model }: SceneComponentProps<SensorPage>) {
  const { isActive } = model.useState();

  return <div> {isActive} </div>;
}
