// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  padding-bottom: 100px;
}

.button {
  background-color: #5e91a5;
  border: 10px;
  min-height: 100px;
  min-width: 250px;
  color: white;
  padding: 25px;
  text-align: center;
  display: block;
  font-size: 16px;
  margin: 10px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  border: 3px solid grey;
}
`, "",{"version":3,"sources":["webpack://./pages/Alertas/estilos.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,cAAc;EACd,eAAe;EACf,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;AACxB","sourcesContent":[".title {\n  padding-bottom: 100px;\n}\n\n.button {\n  background-color: #5e91a5;\n  border: 10px;\n  min-height: 100px;\n  min-width: 250px;\n  color: white;\n  padding: 25px;\n  text-align: center;\n  display: block;\n  font-size: 16px;\n  margin: 10px;\n}\n\n.center {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 200px;\n  border: 3px solid grey;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
