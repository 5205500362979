import { SceneComponentProps, SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import '../estilos.css';
import React from 'react';

interface PageState extends SceneObjectState {
  isActive: boolean;
}

export class PontoContactoPage extends SceneObjectBase<PageState> {
  public static Component = PontoContactoPageRenderer;
}

function PontoContactoPageRenderer({ model }: SceneComponentProps<PontoContactoPage>) {
  const { isActive } = model.useState();

  return <div> {isActive} </div>;
}
