import pluginJson from './plugin.json';

export const PLUGIN_BASE_URL = `/a/${pluginJson.id}`;

export enum ROUTES {
  Home = 'home',
  WithTabs = 'page-with-tabs',
  WithDrilldown = 'page-with-drilldown',
  RenderFromJSON = 'render-from-json',
  HomeAlertas = 'home-alertas',
  CriaAlertas = 'criar-alertas',
  PontoContacto = 'pontos-contacto',
  HomeSensores = 'home-sensores',
  Maquinas = 'maquinas',
  Sensor = 'sensor',
  CriaVisualizacao = 'criar-visualizacao',
}

export const DATASOURCE_REF = {
  uid: 'gdev-testdata',
  type: 'testdata',
};
