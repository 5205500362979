import {
  EmbeddedScene,
  PanelBuilders,
  SceneGridItem,
  SceneGridLayout,
  SceneQueryRunner,
  SceneTimeRange,
} from '@grafana/scenes';

import { RootDashboardJsonType } from '../../utils/DashboardTypes';

import { AxisPlacement, LegendDisplayMode, TooltipDisplayMode } from '@grafana/schema';

export function DashboardRenderer(dashboardJson?: RootDashboardJsonType) {
  if (!dashboardJson) {
    return new EmbeddedScene({
      $timeRange: new SceneTimeRange({ from: 'now-1h', to: 'now' }),
      $data: new SceneQueryRunner({
        datasource: { type: '', uid: '' },
        queries: [],
      }),
      body: new SceneGridLayout({
        children: [
          new SceneGridItem({
            x: 0,
            y: 0,
            width: 12,
            height: 10,
            body: PanelBuilders.timeseries().setTitle('A carregar...').build(),
          }),
        ],
      }),
    });
  } else {
    return DashboardJSON(dashboardJson);
  }
}

export const DashboardJSON = (dashboardJson: RootDashboardJsonType) => {
  const timeRange = new SceneTimeRange({
    from: dashboardJson?.dashboard.time.from,
    to: dashboardJson?.dashboard.time.to,
  });

  const sceneGridItems = dashboardJson.dashboard.panels
    .map((panel, index) => {
      const dataSourceJSON = {
        type: panel.datasource?.type || '',
        uid: panel.datasource?.uid || '',
      };

      const queryRunner = panel.targets
        ? new SceneQueryRunner({
            datasource: dataSourceJSON,
            queries: panel.targets.map((target) => ({
              refId: target.refId || 'A',
              scenarioId: target.scenarioId,
            })),
          })
        : undefined;

      switch (panel.type) {
        case 'piechart': {
          const builder = PanelBuilders.piechart().setTitle(panel.title || 'Sem titulo');
          if (queryRunner) {
            builder.setData(queryRunner);
          }
          return new SceneGridItem({
            x: panel.gridPos.x,
            y: panel.gridPos.y,
            width: panel.gridPos.w,
            height: panel.gridPos.h,
            body: builder.build(),
          });
        }

        case 'timeseries': {
          const tooltipMode = panel.options.tooltip?.mode as keyof typeof TooltipDisplayMode;
          const legendDisplayMode = panel.options.legend?.displayMode as keyof typeof LegendDisplayMode;
          const axisPlacementCustom = panel.fieldConfig.defaults.custom.axisPlacement as keyof typeof AxisPlacement;
          const builder = PanelBuilders.timeseries()
            .setDescription(panel.description)
            .setOption('legend', {
              displayMode:
                LegendDisplayMode[
                  (legendDisplayMode.charAt(0).toUpperCase() +
                    legendDisplayMode.slice(1)) as keyof typeof LegendDisplayMode
                ],
            })
            .setCustomFieldConfig(
              'axisPlacement',
              AxisPlacement[
                (axisPlacementCustom.charAt(0).toUpperCase() +
                  axisPlacementCustom.slice(1)) as keyof typeof AxisPlacement
              ]
            )
            .setOption('legend', { calcs: panel.options.legend?.calcs })
            .setOption('tooltip', {
              mode: TooltipDisplayMode[
                (tooltipMode.charAt(0).toUpperCase() + tooltipMode.slice(1)) as keyof typeof TooltipDisplayMode
              ],
            })
            .setTitle(panel.title || 'Sem titulo');
          if (panel.transparent) {
            builder.setDisplayMode('transparent');
          }
          if (queryRunner) {
            builder.setData(queryRunner);
          }
          return new SceneGridItem({
            x: panel.gridPos.x,
            y: panel.gridPos.y,
            width: panel.gridPos.w,
            height: panel.gridPos.h,
            body: builder.build(),
          });
        }

        case 'barchart': {
          const builder = PanelBuilders.barchart().setTitle(panel.title || 'Sem titulo');
          if (queryRunner) {
            builder.setData(queryRunner);
          }
          return new SceneGridItem({
            x: panel.gridPos.x,
            y: panel.gridPos.y,
            width: panel.gridPos.w,
            height: panel.gridPos.h,
            body: builder.build(),
          });
        }

        case 'bargauge': {
          const builder = PanelBuilders.bargauge().setTitle(panel.title || 'Sem titulo');
          if (queryRunner) {
            builder.setData(queryRunner);
          }
          return new SceneGridItem({
            x: panel.gridPos.x,
            y: panel.gridPos.y,
            width: panel.gridPos.w,
            height: panel.gridPos.h,
            body: builder.build(),
          });
        }

        case 'datagrid': {
          const builder = PanelBuilders.datagrid().setTitle(panel.title || 'Sem titulo');
          if (queryRunner) {
            builder.setData(queryRunner);
          }
          return new SceneGridItem({
            x: panel.gridPos.x,
            y: panel.gridPos.y,
            width: panel.gridPos.w,
            height: panel.gridPos.h,
            body: builder.build(),
          });
        }

        case 'flamegraph': {
          const builder = PanelBuilders.flamegraph().setTitle(panel.title || 'Sem titulo');
          if (queryRunner) {
            builder.setData(queryRunner);
          }
          return new SceneGridItem({
            x: panel.gridPos.x,
            y: panel.gridPos.y,
            width: panel.gridPos.w,
            height: panel.gridPos.h,
            body: builder.build(),
          });
        }

        case 'gauge': {
          const builder = PanelBuilders.gauge().setTitle(panel.title || 'Sem titulo');
          if (queryRunner) {
            builder.setData(queryRunner);
          }
          return new SceneGridItem({
            x: panel.gridPos.x,
            y: panel.gridPos.y,
            width: panel.gridPos.w,
            height: panel.gridPos.h,
            body: builder.build(),
          });
        }

        case 'geomap': {
          const builder = PanelBuilders.geomap().setTitle(panel.title || 'Sem titulo');
          if (queryRunner) {
            builder.setData(queryRunner);
          }
          return new SceneGridItem({
            x: panel.gridPos.x,
            y: panel.gridPos.y,
            width: panel.gridPos.w,
            height: panel.gridPos.h,
            body: builder.build(),
          });
        }

        case 'heatmap': {
          const builder = PanelBuilders.heatmap().setTitle(panel.title || 'Sem titulo');
          if (queryRunner) {
            builder.setData(queryRunner);
          }
          return new SceneGridItem({
            x: panel.gridPos.x,
            y: panel.gridPos.y,
            width: panel.gridPos.w,
            height: panel.gridPos.h,
            body: builder.build(),
          });
        }

        case 'histogram': {
          const builder = PanelBuilders.histogram().setTitle(panel.title || 'Sem titulo');
          if (queryRunner) {
            builder.setData(queryRunner);
          }
          return new SceneGridItem({
            x: panel.gridPos.x,
            y: panel.gridPos.y,
            width: panel.gridPos.w,
            height: panel.gridPos.h,
            body: builder.build(),
          });
        }

        case 'logs': {
          const builder = PanelBuilders.logs().setTitle(panel.title || 'Sem titulo');
          if (queryRunner) {
            builder.setData(queryRunner);
          }
          return new SceneGridItem({
            x: panel.gridPos.x,
            y: panel.gridPos.y,
            width: panel.gridPos.w,
            height: panel.gridPos.h,
            body: builder.build(),
          });
        }

        case 'news': {
          const builder = PanelBuilders.news().setTitle(panel.title || 'Sem titulo');
          if (queryRunner) {
            builder.setData(queryRunner);
          }
          return new SceneGridItem({
            x: panel.gridPos.x,
            y: panel.gridPos.y,
            width: panel.gridPos.w,
            height: panel.gridPos.h,
            body: builder.build(),
          });
        }

        case 'nodegraph': {
          const builder = PanelBuilders.nodegraph().setTitle(panel.title || 'Sem titulo');
          if (queryRunner) {
            builder.setData(queryRunner);
          }
          return new SceneGridItem({
            x: panel.gridPos.x,
            y: panel.gridPos.y,
            width: panel.gridPos.w,
            height: panel.gridPos.h,
            body: builder.build(),
          });
        }

        case 'stat': {
          const builder = PanelBuilders.stat().setTitle(panel.title || 'Sem titulo');
          if (queryRunner) {
            builder.setData(queryRunner);
          }
          return new SceneGridItem({
            x: panel.gridPos.x,
            y: panel.gridPos.y,
            width: panel.gridPos.w,
            height: panel.gridPos.h,
            body: builder.build(),
          });
        }

        case 'statetimeline': {
          const builder = PanelBuilders.statetimeline().setTitle(panel.title || 'Sem titulo');
          if (queryRunner) {
            builder.setData(queryRunner);
          }
          return new SceneGridItem({
            x: panel.gridPos.x,
            y: panel.gridPos.y,
            width: panel.gridPos.w,
            height: panel.gridPos.h,
            body: builder.build(),
          });
        }

        case 'statushistory': {
          const builder = PanelBuilders.statushistory().setTitle(panel.title || 'Sem titulo');
          if (queryRunner) {
            builder.setData(queryRunner);
          }
          return new SceneGridItem({
            x: panel.gridPos.x,
            y: panel.gridPos.y,
            width: panel.gridPos.w,
            height: panel.gridPos.h,
            body: builder.build(),
          });
        }

        case 'table': {
          const builder = PanelBuilders.table().setTitle(panel.title || 'Sem titulo');
          if (queryRunner) {
            builder.setData(queryRunner);
          }
          return new SceneGridItem({
            x: panel.gridPos.x,
            y: panel.gridPos.y,
            width: panel.gridPos.w,
            height: panel.gridPos.h,
            body: builder.build(),
          });
        }

        case 'traces': {
          const builder = PanelBuilders.traces().setTitle(panel.title || 'Sem titulo');
          if (queryRunner) {
            builder.setData(queryRunner);
          }
          return new SceneGridItem({
            x: panel.gridPos.x,
            y: panel.gridPos.y,
            width: panel.gridPos.w,
            height: panel.gridPos.h,
            body: builder.build(),
          });
        }

        case 'trend': {
          const builder = PanelBuilders.trend().setTitle(panel.title || 'Sem titulo');
          if (queryRunner) {
            builder.setData(queryRunner);
          }
          return new SceneGridItem({
            x: panel.gridPos.x,
            y: panel.gridPos.y,
            width: panel.gridPos.w,
            height: panel.gridPos.h,
            body: builder.build(),
          });
        }

        case 'xychart': {
          const builder = PanelBuilders.xychart().setTitle(panel.title || 'Sem titulo');
          if (queryRunner) {
            builder.setData(queryRunner);
          }
          return new SceneGridItem({
            x: panel.gridPos.x,
            y: panel.gridPos.y,
            width: panel.gridPos.w,
            height: panel.gridPos.h,
            body: builder.build(),
          });
        }

        default:
          console.warn(`Tipo de painel não suportado: ${panel.type}`);
          return null;
      }
    })
    .filter((item): item is SceneGridItem => item !== null);

  return new EmbeddedScene({
    $timeRange: timeRange,
    body: new SceneGridLayout({
      children: sceneGridItems,
    }),
  });
};

export default DashboardRenderer;
